import { initializeApp } from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore, CACHE_SIZE_UNLIMITED } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions';
// import {useFirebaseAuth} from "vuefire";

const firebaseApp = initializeApp({
    apiKey: "AIzaSyCB2GUWIO-5iq1UX617hLARh7y-3GSBkic",
    authDomain: "app-ancora.firebaseapp.com",
    projectId: "app-ancora",
    storageBucket: "app-ancora.appspot.com",
    messagingSenderId: "52606457450",
    appId: "1:52606457450:web:8e403dabbdbf4d5c031c13",
    measurementId: "G-165RG9YN1Z"
});
const analytics = getAnalytics(firebaseApp);

// useFirebaseAuth().useDeviceLanguage();

const db = initializeFirestore(firebaseApp, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
});

const functions = getFunctions(firebaseApp, 'southamerica-east1');

// if (process.env.NODE_ENV !== 'production')
//     functions.useEmulator("localhost", 5001);

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
export {db, firebaseApp, functions, analytics};
