export default {
	name: "isAdmin",

	computed: {
		isAdmin() {
			return [
				'LZPgLAQUSGliPuWP57bM' /*Âncora*/,
				'IdLjRaWr2Yi0K4EUHyUN' /*Ejetar*/
			].includes(this.$store.state.customer.id);
		}
	}
}
