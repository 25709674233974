<template>
	<Suspense>
		<template #default>
			<IonApp>
				<IonSplitPane content-id="main-content">
					<ion-menu v-if="autenticado" content-id="main-content" type="overlay">
						<ion-content>
							<ion-list id="inbox-list">
								<div class="profile">
									<ion-note class="cor-personalizada"><strong>{{ $store.state.customer.name }}</strong></ion-note><br>
									<div style="line-height: 20px">
										<ion-note class="cor-personalizada">{{ $store.state.usuario.name }}</ion-note><br>
										<ion-note>{{ $store.state.usuario.email }}</ion-note><br>
									</div>
								</div>

								<ion-menu-toggle auto-hide="false">
									<ion-item-group>
										<ion-item-divider>
											<ion-label>Módulos</ion-label>
										</ion-item-divider>

										<ion-item button @click="$router.push({ name: 'dashboard.index' })" lines="none" detail="false" class="hydrated">
											<ion-icon name="home-outline" slot="start"></ion-icon>
											<ion-label>Dashboard</ion-label>
										</ion-item>

                                        <ion-item
                                            button
                                            @click="$router.push({ name: 'acoes-realizadas.index' })"
                                            lines="none"
                                            detail="false"
                                            class="hydrated"
                                            v-if="$store.state.usuario.permissions['view_actions_for_anyone'] || $store.state.usuario.permissions['view_actions_for_yourself']"
                                        >
                                            <ion-icon name="checkmark-done-circle-outline" slot="start"></ion-icon>
                                            <ion-label>Ações Realizadas</ion-label>
                                        </ion-item>

										<ion-item
											button
											@click="$router.push({ name: 'auditorias.index' })"
											lines="none"
											detail="false"
											class="hydrated"
											v-if="$store.state.usuario.permissions['view_audits_for_anyone'] || $store.state.usuario.permissions['view_audits_for_yourself']"
										>
											<ion-icon name="document-outline" slot="start"></ion-icon>
											<ion-label>Auditorias</ion-label>
										</ion-item>

										<ion-item
											button
											@click="$router.push({ name: 'clientes.index' })"
											lines="none"
											detail="false"
											class="hydrated"
											v-if="isAdmin"
										>
<!--											v-if="$store.state.usuario.permissions['view_customers_for_anyone'] || $store.state.usuario.permissions['view_customers_for_yourself']"-->
											<ion-icon name="people-outline" slot="start"></ion-icon>
											<ion-label>Clientes</ion-label>
										</ion-item>

										<ion-item
											button
											@click="$router.push({ name: 'pacotes.index' })"
											lines="none"
											detail="false"
											class="hydrated"
											v-if="$store.state.usuario.permissions['view_all_packages'] || $store.state.usuario.permissions['view_yourself_packages']"
										>
											<ion-icon name="cube-outline" slot="start"></ion-icon>
											<ion-label>Pacotes</ion-label>
<!--											<ion-badge slot="end">admin</ion-badge>-->
										</ion-item>

										<ion-item
											button
											@click="$router.push({ name: 'questionarios.index' })"
											lines="none"
											detail="false"
											class="hydrated"
											v-if="$store.state.usuario.permissions['view_yourself_questionnaires'] || $store.state.usuario.permissions['view_all_questionnaires']"
										>
											<ion-icon name="list-outline" slot="start"></ion-icon>
											<ion-label>Questionários</ion-label>
<!--											<ion-badge slot="end">admin</ion-badge>-->
										</ion-item>

										<ion-item
											button
											@click="$router.push({ name: 'relatorios.index' })"
											lines="none"
											detail="false"
											class="hydrated"
										>
											<!-- todo -->
											<!--											v-if="$store.state.usuario.permissions['view_all_packages'] || $store.state.usuario.permissions['view_yourself_packages']"-->
											<ion-icon name="newspaper-outline" slot="start"></ion-icon>
											<ion-label>Relatórios</ion-label>
										</ion-item>

										<ion-item
											v-if="$store.state.usuario.permissions['view_users_for_anyone'] || $store.state.usuario.permissions['view_users_for_yourself']"
											button
											@click="$router.push({ name: 'usuarios.index' })"
											lines="none"
											detail="false"
											class="hydrated"
										>
											<ion-icon name="lock-closed-outline"></ion-icon>
											<ion-label>Usuários</ion-label>
										</ion-item>
									</ion-item-group>

									<ion-item-group>
										<ion-item-divider>
											<ion-label>Perfil</ion-label>
										</ion-item-divider>

										<ion-item button lines="none" detail="false" class="hydrated"
											@click="minhaConta"
										>
											<ion-icon name="create-outline" slot="start"></ion-icon>
											<ion-label>Meus dados</ion-label>
										</ion-item>

										<ion-item button @click="logout" lines="none" detail="false" class="hydrated">
											<ion-icon
												name="exit-outline"
												slot="start"
												@click="logout"
											></ion-icon>
											<ion-label>Sair</ion-label>
										</ion-item>
									</ion-item-group>
								</ion-menu-toggle>
							</ion-list>
						</ion-content>
					</ion-menu>

					<ion-router-outlet id="main-content"></ion-router-outlet>
				</IonSplitPane>
			</IonApp>
		</template>
		<template #fallback>
			<div><!-- todo --></div>
		</template>
	</Suspense>
</template>

<script>
	//Ícones Ionic
    import {
        homeOutline,
        peopleOutline,
        listOutline,
        exitOutline,
        cubeOutline,
        lockClosedOutline,
        createOutline,
        documentOutline,
        newspaperOutline,
        checkmarkDoneCircleOutline
    } from "ionicons/icons";
	import { addIcons } from "ionicons";
	addIcons({
		"document-outline": documentOutline,
		"home-outline": homeOutline,
		"people-outline": peopleOutline,
		"list-outline": listOutline,
		"exit-outline": exitOutline,
		"cube-outline": cubeOutline,
		"lock-closed-outline": lockClosedOutline,
		"create-outline": createOutline,
		"newspaper-outline": newspaperOutline,
        "checkmark-done-circle-outline": checkmarkDoneCircleOutline,
	});

	//Componentes Ionic
	import {
		IonApp,
		IonContent,
		IonItem,
		IonLabel,
		IonList,
		IonMenu,
		IonMenuToggle,
		IonRouterOutlet,
		IonSplitPane,
		IonItemDivider,
		IonIcon,
		IonItemGroup,
		// IonBadge
		IonNote,
	} from '@ionic/vue';

	//Mixins
	import isAdmin from "@/mixins/isAdmin";
	import {toast} from "@/helpers";

	export default {
		name: 'App',

		data() {
			return {
				refreshing: false,
				registration: null,
			}
		},

		mixins: [
			isAdmin
		],

		methods: {
			logout() {
				this.$store.dispatch(`logout`);
			},

			minhaConta() {
				window.location = 'https://solucoesancora.com.br/minha-conta';
			},

			refreshApp() {
				toast('Instalando nova versão do app. A página está sendo atualizada!');

				//Garante enviar apenas uma mensagem de 'pular espera' se o SW estiver esperando
				if (!this.registration || !this.registration.waiting) return;
				//Envia mensagem para o SW para pular a espera e ativar o novo SW
				this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
			},

			async updateAvailable(event) {
				//Armazena o registro do SW para que possamos enviar uma mensagem
				this.registration = event.detail;

				this.refreshApp();
			}
		},

		computed: {
			autenticado() {
				return this.$_.get(this.$store,'state.usuario');
			}
		},

		created() {
			//Ouve o evento personalizadoswUpdated a partir do registro do SW
			document.addEventListener('swUpdated', this.updateAvailable, { once: true });

			//Previne várias atualizações
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				//Não deixa ir adiante se já estiver atualizando
				if (this.refreshing) return;

				this.refreshing = true;
				window.location.reload();
			});
		},

		components: {
			IonApp,
			IonContent,
			IonItem,
			IonLabel,
			IonList,
			IonMenu,
			IonMenuToggle,
			IonRouterOutlet,
			IonSplitPane,
			IonItemDivider,
			IonIcon,
			IonItemGroup,
			// IonBadge
			IonNote
		},
	}
</script>

<style scoped>
	.profile {
		margin-left: 15px;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	ion-icon {
		margin-right: 10px;
	}

	@media (prefers-color-scheme: dark) {
		.cor-personalizada {
			color: white;
		}
	}

	@media (prefers-color-scheme: light) {
		.cor-personalizada {
			color: #272727;
		}
	}
</style>
