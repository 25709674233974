/* eslint-disable no-console */

import {register} from 'register-service-worker'
import {toast} from "@/helpers";

if (process.env.NODE_ENV === 'production') {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.log('Este app está sendo servido pelo cache de um service worker. Para mais detalhes, visite https://goo.gl/AFskqB.')
		},
		registered() {
			console.log('Service worker foi registrado.');
		},
		cached() {
			console.log('Conteúdo foi cacheado para uso offline.');
		},
		updatefound() {
			toast('Nova versão está sendo baixada...');
		},
		updated(registration) {
			console.log("Nova versão disponível!");
			document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }));
		},
		offline() {
			toast('Sem conexão com a internet!');
		},
		error(error) {
			console.error('Erro durante o registro do service worker:', error)
		}
	})
}
