import { createApp } from 'vue';
import App from './pages/index/App.vue';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import router from './router';
import './registerServiceWorker';
import store from './store';

/* VueFire */
import {VueFire, VueFireAuth, VueFireFirestoreOptionsAPI} from 'vuefire';
import { firebaseApp } from './firebase'

/* Apexcharts */
import VueApexCharts from "vue3-apexcharts";

/* Lodash */
import _ from 'lodash';

//Ícones
import FontAwesomeIcon from './icons';

import VueTranslate from 'vue-simple-translator';
import translations from '@/translations.json';

import VTooltip from 'v-tooltip';

const app = createApp(App)
	.use(IonicVue)
	.use(router)
	.use(VueFire, {
		firebaseApp,
		modules: [
			VueFireAuth(),
			VueFireFirestoreOptionsAPI()
		]
	})
	.use(store)
	.use(VTooltip)
	.use(VueApexCharts)
	.use(VueTranslate, {
		availableLanguages: {
			pt_BR: 'Português do Brasil'
		},
		defaultLanguage: 'pt_BR',
		translations: translations
	})
	.directive("lowercase", {
		updated: function (el) {
			if (_.get(el,'value'))
				el.value = el.value.toLowerCase()
		}
	})
	.directive("uppercase", {
		updated: function (el) {
			if (_.get(el,'value'))
				el.value = el.value.toUpperCase()
		}
	});

app.component('font-awesome-icon', FontAwesomeIcon);

app.config.globalProperties.$_ = _;

router.isReady().then(() => {
	app.mount('#app');
});
