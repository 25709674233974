import { library } from '@fortawesome/fontawesome-svg-core';

//Brands
import {
    faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';

//Regular

//Solid
import {
    faTasks,
    faUsers,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
    faTasks,
    faUsers,
    faWhatsapp,
    faSpinner
);
export default FontAwesomeIcon;
